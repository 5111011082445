/**
 * The bar chart module of Chartist that can be used to draw unipolar or bipolar bar and grouped bar charts.
 *
 * @module Chartist.Tagcloud
 */
/* global Chartist */
(function (window, document, Chartist) {
  'use strict';
  /**
		 * Default options in bar charts. Expand the code view to see a detailed list of options with comments.
		 *
		 * @memberof Chartist.Tagcloud
		 */
  var defaultOptions = {
    // Specify a fixed width for the chart as a string (i.e. '100px' or '50%')
    width: undefined,
    // Specify a fixed height for the chart as a string (i.e. '100px' or '50%')
    height: undefined,
    // minimum font-size used
    minSize: null,
    // maximum font-size used
    maxSize: null,
    // the clouds word distribution width scaling factor
    cloudRatioX: 1,
    // the clouds word distribution height scaling factor
    cloudRatioY: 1,
    // maximum number of words
    maxWords: 200,
    // for random comparison out of 10
    verticalChance: 0,
    wordPadding: {
      top: -1,
      right: 0,
      bottom: -1,
      left: 0
    },
    // Overriding the natural high of the chart allows you to zoom in or limit the charts highest displayed value
    high: undefined,
    // Overriding the natural low of the chart allows you to zoom in or limit the charts lowest displayed value
    low: undefined,
    // Use only integer values (whole numbers) for the scale steps
    onlyInteger: true,
    // Padding of the chart drawing area to the container element and labels as a number or padding object {top: 5, right: 5, bottom: 5, left: 5}
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    },
    // // If set to true then each bar will represent a series and the data array is expected to be a one dimensional array of data values rather than a series array of series. This is useful if the bar chart should represent a profile rather than some data over time.
    distributeSeries: false,
    // If true the whole data is reversed including labels, the series order as well as the whole series data arrays.
    reverseData: false,
    // Override the class names that get used to generate the SVG structure of the chart
    classNames: {
      chart: 'ct-chart-bar',
      horizontalBars: 'ct-horizontal-bars',
      label: 'ct-label',
      labelGroup: 'ct-labels',
      series: 'ct-series',
      Tagcloud: 'ct-bar',
      grid: 'ct-grid',
      gridGroup: 'ct-grids',
      vertical: 'ct-vertical',
      horizontal: 'ct-horizontal',
      start: 'ct-start',
      end: 'ct-end'
    }
  };
  /**
		 * Creates a new chart
		 *
		 */
  /**
   * @author Anthony Pigeot - http://anthonypigeot.com
   * @contributor Bertrand Coizy - http://twitter.com/etribz
   */
  /**
     SMath constructor
     @params: associative array, possible values:
     - nbCos: nb of apparoximative values (default:360)
     - nbSin: nb of apparoximative values (default:360)
     Note: if either sin or cos has been calculated, a default cosine array will be created
     TODO: atan things
     */
  var SMath = function (params) {
    // Constants
    this.RAD2DEG = 180 / Math.PI;
    this.DEG2RAD = Math.PI / 180;
    this.PI2 = Math.PI * 2;
    this.nbCos = params.nbCos || 360;
    this.nbSin = params.nbSin || 360;
    // We create cos functions and array only if no params has been passed or nbCos has been specified
    if (!params || params && params.nbCos) {
      this.cosTable = new Float32Array(this.nbCos);
      // The cos factor is the difference in radians between two lookup values
      this.cosFactor = this.nbCos / this.PI2;
      this.fillCache(this.cosTable, this.cosFactor, Math.cos);
      SMath.prototype.cos = function (angle) {
        angle %= this.PI2;
        if (angle < 0) {
          angle += this.PI2
        }
        return this.cosTable[angle * this.cosFactor | 0]
      }
    }
    if (!params || params && params.nbSin) {
      this.sinTable = new Float32Array(this.nbSin);
      this.sinFactor = this.nbSin / this.PI2;
      this.fillCache(this.sinTable, this.sinFactor, Math.sin);
      SMath.prototype.sin = function (angle) {
        angle %= this.PI2;
        if (angle < 0) {
          angle += this.PI2
        }
        return this.sinTable[angle * this.sinFactor | 0]
      }
    }
  };
  SMath.prototype.fillCache = function (array, factor, mathFunction) {
    var length = array.length;
    for (var i = 0; i < length; i += 1) {
      array[i] = mathFunction(i / factor)
    }
  };
  //  var maxSize;
  var sMath = new SMath({
    nbCos: 360,
    nbSin: 360
  });
  // Resolution of the lookup table. More = more precision
  var drawnBoxesOffset = [];
  function createChart(options) {
    Chartist.extend(defaultOptions, options);
    drawnBoxesOffset = [];
    var data = this.data;
    // sort the series to get correct order independently from given order
    this.data.series.sort(function (a, b) {
      return a.data[0] - b.data[0]
    }).reverse();
    // Create new svg element
    this.svg = Chartist.createSvg(this.container, options.width, options.height, options.classNames.chart);
    this.svg._node.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    var seriesGroup = this.svg.elem('g');
    var chartRect = Chartist.createChartRect(this.svg, options, defaultOptions.padding);
    var mincount = data.series[data.series.length - 1].data[0];
    var maxcount = data.series[0].data[0];
    var difference = maxcount - mincount;
    // Draw the series
    data.series.forEach(function (series, seriesIndex) {
      // Current series SVG element
      var seriesElement = seriesGroup.elem('g');
      // Write attributes to series group element. If series name or meta is undefined the attributes will not be written
      seriesElement.attr({
        'series-name': series.name,
        'series-index': seriesIndex,
        'meta': Chartist.serialize(series.meta)
      }, Chartist.xmlNs.uri);
      // Use series class from series data or if not set generate one
      seriesElement.addClass([
        options.classNames.series,
        options.classNames.series + '-' + Chartist.alphaNumerate(seriesIndex % 8)
      ].join(' '));
      // seriesElement.addClass([options.classNames.series, (series.className || options.classNames.series + '-' + Chartist.alphaNumerate(Math.floor((Math.random() * 7) + 1)))].join(' '));
      if (seriesIndex > options.maxWords) {
        return
      }
      // rotate text randomly
      var transform = 'rotate(' + (Math.floor(Math.random() * 10 + 1) <= options.verticalChance ? 90 : 0) + ')';
      // create and append text element
      var title = new Chartist.Svg('text');
      title.text(series.name);
      // use font-size based on total width	if not defined otherwise
      var maxSize = options.maxSize ? options.maxSize : chartRect.width() / 10;
      var minSize = options.minSize ? options.minSize : chartRect.width() / 100;
      var sizeFactor = maxSize - minSize;
      title.attr({
        transform: transform,
        class: 'ct-text',
        'font-size': Math.round(series.data[0] / difference * sizeFactor) + minSize + 'px'
      });
      seriesElement.append(title, false);
      placeElement(seriesElement, chartRect, Math.floor(Math.random() * 4 + 1), options);
      this.eventEmitter.emit('draw', Chartist.extend({
        type: 'tagcloud',
        meta: Chartist.getMetaData(series, 0),
        series: series,
        seriesIndex: seriesIndex,
        chartRect: chartRect,
        group: seriesElement,
        element: Tagcloud
      }))
    }.bind(this));
    // at the end, check, if the group fits and 
    // optionally scale the series group to fit into canvas
    var transform = '';
    var groupOffset = getOffset(seriesGroup, options);
    var widthScaleFactor = Math.round(chartRect.x2 / (groupOffset.width + groupOffset.width * 0.2) * 1000) / 1000;
    var heightScaleFactor = Math.round(chartRect.y1 / (groupOffset.height + groupOffset.height * 0.2) * 1000) / 1000;
    if (widthScaleFactor < heightScaleFactor) {
      transform = 'translate(' + -(chartRect.x2 / 2) * (widthScaleFactor - 1) + ',' + -(chartRect.y1 / 2) * (widthScaleFactor - 1) + ')' + ' scale(' + widthScaleFactor + ')'
    } else {
      transform = 'translate(' + -(chartRect.x2 / 2) * (heightScaleFactor - 1) + ',' + -(chartRect.y1 / 2) * (heightScaleFactor - 1) + ')' + ' scale(' + heightScaleFactor + ')'
    }
    seriesGroup.attr({ transform: transform });
    this.eventEmitter.emit('created', {
      chartRect: chartRect,
      svg: this.svg,
      options: options
    })
  }
  /**
		 * helper functions
		 */
  function placeElement(seriesElement, chartRect, direction, options) {
    var elOffset = getOffset(seriesElement, options);
    // random amount to step in the spiral
    var step = Math.floor(Math.random() * 5 + 1);
    var i = 0;
    // if(seriesElement.attr.valueOf('series-index', null) == 0) {
    var x = chartRect.width() / 2 - elOffset.width / 2;
    // + Math.random(-chartRect.x2 / 2, chartRect.x2 / 2);
    var y = chartRect.height() / 2 + elOffset.height / 2;
    // + Math.random(-chartRect.y1 / 2, chartRect.y1 / 2);
    // }
    var placeFound = false;
    do {
      // change coordinates in spiral form.
      // to achieve a good distribution, change the direction each time
      switch (direction) {
      case 1: {
          x = x + Math.floor(i / 2 * options.cloudRatioX * sMath.cos(i));
          y = y + Math.floor(i / 2 * options.cloudRatioY * sMath.sin(i));
          break
        }
      case 2: {
          x = x + Math.floor(i / 2 * options.cloudRatioX * sMath.cos(i));
          y = y - Math.floor(i / 2 * options.cloudRatioY * sMath.sin(i));
          break
        }
      case 3: {
          x = x - Math.floor(i / 2 * options.cloudRatioX * sMath.cos(i));
          y = y - Math.floor(i / 2 * options.cloudRatioY * sMath.sin(i));
          break
        }
      case 4: {
          x = x - Math.floor(i / 2 * options.cloudRatioX * sMath.cos(i));
          y = y + Math.floor(i / 2 * options.cloudRatioY * sMath.sin(i));
          break
        }
      default: {
          x = x + Math.floor(i / 2 * options.cloudRatioX * sMath.cos(i));
          y = y + Math.floor(i / 2 * options.cloudRatioY * sMath.sin(i));
          break
        }
      }
      // move group containing the word to new position
      if (!isNaN(x) & !isNaN(y)) {
        seriesElement.attr({ transform: 'translate(' + x + ',' + y + ')' })
      } else {
        placeFound = true
      }
      // check if we have placed it
      placeFound = !overlaps(getOffset(seriesElement, options));
      i += step
    } while (!placeFound);
    // add to the array of placed boxes
    drawnBoxesOffset.push(getOffset(seriesElement, options))
  }
  function overlaps(seriesElementOffset) {
    // get the current number of placed words
    var num = drawnBoxesOffset.length;
    // loop through all drawn boxes
    for (var j = 0; j < num; j += 1) {
      // check if the box touches the next box
      if (intersects(seriesElementOffset, drawnBoxesOffset[j])) {
        return true
      }
    }
    // if we get here then it doesnt overlap anything so return false
    return false
  }
  function intersects(seriesElementOffset, referenceOffset) {
    // if word is above currently looped element
    if (seriesElementOffset.top > referenceOffset.bottom) {
      return false
    }
    // if word is under currently looped element and container bottom
    if (seriesElementOffset.bottom < referenceOffset.top) {
      return false
    }
    // if word is right of currently looped element
    if (seriesElementOffset.left > referenceOffset.right) {
      return false
    }
    // if word is left of currently looped element
    if (seriesElementOffset.right < referenceOffset.left) {
      return false
    }
    // if we get here then the boxes intersects/overlaps each other
    return true
  }
  var getOffset = function (el, options) {
    var boundingRect = el._node.getBoundingClientRect();
    return {
      top: boundingRect.top - boundingRect.height * (options.wordPadding.top / 100),
      bottom: boundingRect.bottom + boundingRect.height * (options.wordPadding.bottom / 100),
      left: boundingRect.left - boundingRect.width * (options.wordPadding.left / 100),
      right: boundingRect.right + boundingRect.width * (options.wordPadding.right / 100),
      width: boundingRect.width,
      height: boundingRect.height
    }
  };
  /**
		 * This method creates a new bar chart and returns API object that you can use for later changes.
		 *
		 * @memberof Chartist.Tagcloud
		 * @param {String|Node} query A selector query string or directly a DOM element
		 * @param {Object} data The data object that needs to consist of a labels and a series array
		 * @param {Object} [options] The options object with options that override the default options. Check the examples for a detailed list.
		 * @param {Array} [responsiveOptions] Specify an array of responsive option arrays which are a media query and options object pair => [[mediaQueryString, optionsObject],[more...]]
		 * @return {Object} An object which exposes the API for the created chart
		 *
		 * @example
		 * // Create a simple bar chart
		 * var data = {
		 *   labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
		 *   series: [
		 *     [5, 2, 4, 2, 0]
		 *   ]
		 * };
		 *
		 * // In the global name space Chartist we call the Bar function to initialize a bar chart. As a first parameter we pass in a selector where we would like to get our chart created and as a second parameter we pass our data object.
		 * new Chartist.Tagcloud('.ct-chart', data);
		 *
		 * @example
		 * // This example creates a bipolar grouped bar chart where the boundaries are limitted to -10 and 10
		 * new Chartist.Tagcloud('.ct-chart', {
		 *   labels: [1, 2, 3, 4, 5, 6, 7],
		 *   series: [
		 *     [1, 3, 2, -5, -3, 1, -6],
		 *     [-5, -2, -4, -1, 2, -3, 1]
		 *   ]
		 * }, {
		 *   seriesBarDistance: 12,
		 *   low: -10,
		 *   high: 10
		 * });
		 *
		 */
  function Tagcloud(query, data, options, responsiveOptions) {
    Chartist.Tagcloud.super.constructor.call(this, query, data, defaultOptions, Chartist.extend({}, defaultOptions, options), responsiveOptions)
  }
  // Creating bar chart type in Chartist namespace
  Chartist.Tagcloud = Chartist.Base.extend({
    constructor: Tagcloud,
    createChart: createChart
  })
}(window, document, Chartist))